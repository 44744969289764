import { format } from 'date-fns';
import { useEffect } from 'react';
import { saveAs } from 'file-saver';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickOutsideHandler(ref, fn) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fn?.();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

export function findSelectedOption(value, optionsList) {
    return optionsList?.find((option) => option.value === value) ?? null;
}

/**
 *
 * @param {any} value
 * @param {React.ReactNode} display
 * @returns
 */
export function toOption(value, display) {
    return { value, label: display || value };
}

export function prettyDate(d) {
    if (!d) {
        return '';
    }
    const pretty = Date.parse(d);
    return format(pretty, 'yyyy-MM-dd HH:mm');
}
/** 
 * @param {string} title
 * @param {string} heading
 * @param {Object[]} data
 * @returns
 */
export function downloadAsCsv(title = 'CsrDownload', heading, data) {
    /**
     * This is roundabout way of downloading as excel.
     * we should replace this with download as excel
     * by refactoring the components to use common data table 
     */
    const now = format(new Date(), 'yyyy-MM-dd.HH-mm-ss');
    const filename = `${title}-${now}.csv`;
    const modifiedData = data
        .map((row) =>
            Object.values(row)
                // the below logic converts each value in row to string
                // here, we faced a problem where the comma which is part of string value
                // was being inferred as csv separator hence we wanted to enclose it in
                // quotes but that led to another problem of already existing quotes mixing up
                // with new ones. hence we are replacing the existing quotes with single quotes
                .map((cell) => `"${cell?.toString?.().replaceAll('"', '\'') ?? ''}"`)
                .join(',')
        )
        .join('\n');
    const finalReportData = heading.concat(modifiedData);
    const blob = new Blob([finalReportData], { type: 'text/csv; header=present; charset=utf-8' });
    saveAs(blob, filename);
}

export function calculateTermInMonths(startDate, endDate) {

    // this funciton explicity ignores times and assumes the resolution is only down to the day
    const [startYear, startMonth, startDay] = toDateParts(startDate);
    const [endYear, endMonth, endDay] = toDateParts(endDate);
    const daysInLastMonth = new Date(endYear, endMonth, 0).getDate();
    const yearDiff = (endYear - startYear) * 12;
    const monthDiff = endMonth - startMonth;
    // this calculation expects the end date to be one day before the start
    const dayDiff = (endDay - startDay + 1) / daysInLastMonth;
    
    return Number((yearDiff + monthDiff + dayDiff).toFixed(3));
}    
    
function toDateParts(date) {
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
}

/**
 * Formats the date to iso date string YYYY-MM-DD
 * @param {Date} date 
 */
export function dateToIsoDateString(date) {
    if (date instanceof Date && !isNaN(date.getTime())) {
        // create UTC 12 am of local date (if not toISOString might give a different date due to timezone)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
        return date.toISOString().slice(0, 10)
    } else {
        return null
    }
}

/**
 * 
 * @param val
 * @returns true if val is a string of ISO date time format YYYY-MM-DD
 */
export function isIsoDateString(val) {
    const isoDateStringFormat = /^\d{4}-\d{2}-\d{2}$/;

    // check the format
    if (!isoDateStringFormat.test(val)) {
        return false;
    }

    // validate the value
    const d = new Date(val);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString().slice(0, 10) === val;
}

/**
 * @returns date object if val is a  valid ISO string date time format YYYY-MM-DDTHH:MN:SS.MSSZ
 */
export function isoStringToDate(val) {
    const isoStringFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

    // check the format
    if (!isoStringFormat.test(val)) {
        return null;
    }

    // validate the value
    const d = new Date(val);
    if (d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === val) {
        return d
    }
    return null
}


/**
 * Converts iso date string of format YYYY-MM-DD to date object
 * @param dateString date string in the format of YYYY-MM-DD 
 * @returns date object of given date at 12 am (local timezone)
 */
export function isoDateStringToDate(dateString) {
    if (isIsoDateString(dateString)) {
        const [year, month, day] = dateString.split('-')
        return new Date(+year, +month - 1, +day, 0, 0, 0, 0);
    } else {
        return null;
    }
}
